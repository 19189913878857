import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Layout from '../components/layout'

const Sponsors = (props) => (
    <Layout>
        <Helmet>
            <title>Sponsorship Levels</title>
            <meta name="CHCSD Sponsorship Levels" content="Sponsorship Levels Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Sponsorships</h1>
                    </header>

                    <div className="grid-wrapper">
                        <div className="col-12">

                            <h4>Sponsorship Levels</h4>
                            <p>CHCSD's success is in no small part due to the generosity of its many wonderful sponsors. Please consider supporting the conference, while gaining valuable exposure to the area's homeschool families, with one of the tax deductible sponsorship opportunities listed below.</p>
                            <dl>
                                <dt>Gold - $1,000</dt>
                                <dd>
                                    <p>Gold level sponsors receive a full-page advertisement in the conference program and one year advertising on both the CHCSD and TEACHSD websites. Gold level sponsors also receive recognition in the monthly member newsletter and social media platforms.</p>
                                    <p>Print Ad Full Page: 4.5” w x 7.5” h
                                        <br />Resolution: 300 dpi
                                        <br />CMYK Full Color
                                        <br />No bleed
                                        <br />Preferred formats: .EPS, .PDF
                                    </p>
                                    <p>Web Ad: 300 x 500 px
                                        <br />Full color
                                        <br />Preferred formats: PNG, JPG
                                        <br />Resolution: 72ppi
                                    </p>
                                </dd>
                                <dt>Silver - $500</dt>
                                <dd>
                                    <p>Silver level sponsors receive a half-page advertisement in the conference program and one month advertising on both the CHCSD and TEACHSD websites. Silver level sponsors also receive recognition in the monthly member newsletter and social media platforms.</p>
                                    <p>Print Ad Half Page: 4.5” w x 3.5” h OR 2.175” w x 6.75” h
                                        <br />Resolution: 300 dpi
                                        <br />CMYK Full Color 
                                        <br />No bleed 
                                        <br />Preferred formats: .EPS, .PDF 
                                    </p> 
                                    <p>Web Ad: 300 x 250 px 
                                        <br />Full color 
                                        <br />Preferred formats: PNG, JPG 
                                        <br />Resolution: 72ppi
                                    </p>
                                </dd>
                                <dt>Bronze - $250</dt>
                                <dd>
                                    <p>Bronze level sponsors receive a quarter-page advertisement in the conference program.</p>
                                    <p>Print Ad Quarter Page: 4.5” w x 2” h
                                        <br />Resolution: 300 dpi
                                        <br />CMYK Full Color
                                        <br />No bleed
                                        <br />Preferred formats: .EPS, .PDF
                                    </p>
                                </dd>
                                <dt>Contributor $100</dt>
                                <dd>
                                    <p>Contributor level sponsors are listed in the conference program.</p>
                                </dd>
                                <dt>Premier</dt>
                                <dd>
                                    <p>Premier sponsors make a multi-year commitment at the Gold or Silver level. Premier sponsors receive a 6-foot free-standing banner of their design prominently displayed at the conference in addition to the benefits listed above.</p>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <header className="minor">
                        <h2>For more details, or to become a sponsor, contact <a href="mailto:sponsors@chcsd.org?subject=CHCSD%202024%20Sponsorship%20Question" target="_blank">sponsors@chcsd.org</a>.</h2>
                    </header>

                </div>
            </section>

        </div>

    </Layout>
)

export default Sponsors